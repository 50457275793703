<template>
  <div>
    <h1 v-if="!event">Your Trial Event</h1>
    <EventHeader v-if="event" :event="event" />

    <v-alert type="success" tile prominent>
      <p>All good, your Trial Event Is Ready to Go!</p>
      <p>We just sent you an email with detailed instructions on how to get started.</p>
    </v-alert>

    <div v-if="event">

    <h3>Your Event</h3>
      <p>
        Your trial event is active till {{ event.till | localDate }}. For the best experience, open the Join Now link on your mobile, or scan the QR code below to get started.
      </p>
      <p>
        <v-btn color="primary" :href="event.join_url">Join Now</v-btn>
        <v-btn outlined color="primary" class="ml-4" :to="{name: 'event', params: {id: event.id}}">View Leaderboards</v-btn>
        <v-btn text color="primary" class="ml-4" :to="{name: 'eventmanagerView', params: {id: event.id}}">Admin Dashboard</v-btn>
      </p>
    </div>

    <v-card v-if="event" class="grey lighten-3 mb-4" max-width="500">
      <v-card-title class="subtitle">
        Invite Information
      </v-card-title>
      <v-card-text>
        <p>Scan this QR code with your phone to quickly join this event using our app. This will download the iOS or Android depending on the phone.</p>
        <p>Share this code with the other participants so they can join as well.</p>
        <p>
          <QrCode :data="event.join_url" />
        </p>
      </v-card-text>
      <v-card-actions v-if="event.join_url">
        <v-btn text color="accent" small @click="$helpers.copyToClipboard(event.join_url, $toast)"><v-icon small class="mr-2">fa-copy</v-icon> Copy Link</v-btn>
        <v-btn text small color="accent" @click="emailInviteToMe">
          <v-icon small class="mr-2">fadl fa fa-envelope</v-icon> Email me
        </v-btn>
      </v-card-actions>
    </v-card>

    <h3>Next Steps</h3>
    <p>
      Take your time to connect your fitness tracker and try out our platform. Once you have a good idea on how easy it is to get started you can get started with the real event.
    </p>
    <p>
      <v-btn outlined color="primary" to="/contact">Contact Us</v-btn>
      <v-btn text color="primary" class="ml-4" :to="{name: 'eventmanagerCreate'}">Create Real Event</v-btn>
    </p>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import BigButton from "@/components/BigButton";
import QrCode from '@/components/generic/QrCode.vue'
import EventHeader from "@/components/EventHeader";
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "StartTrial",
  components: {
    BigButton,
    EventHeader,
    QrCode,
  },
  props: {
  },
  data: function() {
    return {
      tenant: tenant,
      valid: true,
      timeZoneOlson: null,
      event: null,
    };
  },
  created() {
  },
  mounted() {

    EventBus.$on('login-state-change', async user => {
      if (user) {
        // logged in during the process
        this.loadData();
      }
    });
    this.loadData();
  },
  methods: {
    async loadData() {
      if (this.user) {
        this.event = (await eventManagerService.get(this.$route.params.id)).data;
      }
    },
    async emailInviteToMe() {
      const response = await eventManagerService.sendInviteEventEmailToCode(this.event.id, /*join_code:*/ null);
      this.$helpers.toastResponse(this, response.data, 'The invite email is on it\'s way! Please check your inbox in a few minutes from now.');
    },
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  }
};
</script>

<style scoped>
</style>